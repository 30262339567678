import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2a8b4b88"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "batch"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => _ctx.visible = $event),
    title: _ctx.title,
    onConfirmOk: _ctx.onConfirm,
    width: "600px"
  }), {
    form: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
      type: "textarea",
      rows: 10,
      modelValue: _ctx.mdtids,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.mdtids = $event),
      placeholder: "请用英文,隔开或回车"
    }, null, 8, ["modelValue"])])]),
    footer: _withCtx(() => []),
    _: 1
  }, 16, ["visible", "title", "onConfirmOk"]);
}