import { defineComponent, toRefs, reactive, defineAsyncComponent } from "vue";
import { useMessage } from '@/hooks/web/useMessage';
import { useDebounce } from '@/hooks/core/useDebounce';
export default defineComponent({
  name: "BatchSearch",
  components: {
    //element-plus
    Eldialog: defineAsyncComponent(() => import("@/components/Eldialog/index.vue"))
  },
  props: {},
  emits: ["handleBatch"],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      title: '多个IMEI号查询',
      visible: false,
      mdtids: "",
      confirm() {
        try {
          if (!refData.mdtids) throw "请输入IMEI号";
          refData.mdtids = refData.mdtids.replace(/[\n\s，]/g, ',');
          emit('handleBatch', refData.mdtids);
          refData.visible = false;
        } catch (msg) {
          message.warning(msg);
        }
      }
    });
    const [onConfirm] = useDebounce(refData.confirm, 300);
    return {
      ...toRefs(refData),
      onConfirm
    };
  }
});